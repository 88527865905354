<template>
  <div>
    <NoplateAlert v-if="noplateAlertEdit" :noplateMsg="noplateMsg"></NoplateAlert>
  </div>
</template>

<script>
import { Dialog } from "vant";
import NoplateAlert from "./components/noplateAlert";

export default {
  name: "GetCode",
  components: {
    [Dialog.Component.name]: Dialog.Component,
    NoplateAlert,
  },
  data() {
    return {
      code: "",
      openId: "",
      alipayuserId: "",
      type: null,
      noplateAlertEdit: false,
      noplateMsg: {},
      domain: process.env.VUE_APP_DOMAIN,
    };
  },
  created() {
    let shortUrlmsg = this.$route.params.urlmsg ? this.$route.params.urlmsg : "";
    // http://surl.zhongzhiiov.com  http://testlocal.payment.zhongzhiiov.com
    if (shortUrlmsg !== "" && shortUrlmsg !== null && shortUrlmsg !== "index") {
      this.$axios
        .get(`http://surl.zhongzhiiov.com/api/pay/getLongUrlByShortUrl/${shortUrlmsg}`)
        .then((res) => {
          let url = res.data.data;
          window.location.replace(url);
        })
        .catch((e) => {
          alert(JSON.stringify(e));
        });
    } else {
      // 一开始就执行，一般都是车主正常扫码；重定向之后会再次执行，可以拿到链接中的参数
      let obj = this.getRequest(decodeURIComponent(window.location.href));
      // 重定向后为微信链接
      if (obj.inteface === "wxpay" && obj.code !== "") {
        // 保存type
        this.type = obj.type;
        localStorage.setItem("type", this.type);
        localStorage.setItem("firstUrl", window.location.href);
        // 获取微信openid
        this.getOpenId(obj.code);
      }
      // 重定向后为支付宝链接
      else if (obj.inteface === "alipay" && obj.auth_code !== "") {
        // 保存type
        this.type = obj.type;
        localStorage.setItem("type", this.type);
        // 获取支付宝userid
        this.getUserid(obj.auth_code);
      } else {
        // 车主正常扫码
        this.judge();
      }
    }
  },
  mounted() {},
  destroyed() {},
  methods: {
    // 判断支付宝还是微信扫码
    judge() {
      let userAgent = window.navigator.userAgent.toLowerCase();
      if (userAgent.match(/Alipay/i) == "alipay") {
        let inteface = "alipay";
        this.getLink(inteface);
      } else if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
        let inteface = "wxpay";
        this.getLink(inteface);
      } else if (userAgent.match(/Unionpay/i) == "unionpay") {
        let obj = this.getRequest(decodeURIComponent(window.location.href));
        let appId = obj.appId;
        let itemId = obj.itemId;
        this.$axios.get(`${this.domain}/api/jinhua/select/${appId}/${itemId}`).then((res) => {
          if (res.data.data !== null) {
            let inteface = "unionpay";
            this.getLink(inteface);
          } else {
            this.$alert(`请使用支付宝或微信扫码`, "提示")
              .then(() => {
                return;
              })
              .catch(() => {
                return;
              });
          }
        });
      } else {
        alert("请使用微信或者支付宝扫码支付");
      }
    },
    // 调取链接
    getLink(inteface) {
      let that = this;
      // let local = encodeURIComponent(window.location.href); //获取当前url
      // 解构出链接中的参数
      let obj1 = this.getRequest(window.location.href);
      let obj = this.getRequest(decodeURIComponent(window.location.href));
      let parkingName = null;
      if (inteface == "unionpay" && this.checkIsAppleDevice()) {
        parkingName = decodeURI(obj1.parkingName);
      } else {
        parkingName = obj.parkingName;
      }
      let parkRecordId = obj.parkRecordId;
      let parkCode = obj.parkCode;
      let appId = obj.appId;
      let itemId = obj.itemId;
      // 停车场无牌车二维码参数
      let type = obj.type;
      let channelId = obj.channelId;
      // 停车场优惠券
      let itemName = obj.itemName;
      let couponId = obj.couponId;
      let businessName = obj.businessName;
      let couponName = obj.couponName;
      let markType = Number(obj.markType);
      let couponType = Number(obj.couponType);
      let value = Number(obj.value);
      let mark = obj.mark;
      let userId = obj.userId;
      let businessId = obj.businessId;
      let chan = obj.chan;
      let chanUuid = obj.chanUuid;
      if (couponName) {
        localStorage.setItem("couponName", couponName);
        localStorage.setItem("itemName", itemName);
        localStorage.setItem("businessName", businessName);
        localStorage.setItem("userId", userId);
        localStorage.setItem("businessId", businessId);
      }
      localStorage.setItem("appId", appId);
      localStorage.setItem("parkCode", parkCode);
      localStorage.setItem("parkingName", parkingName);
      localStorage.setItem("parkRecordId", parkRecordId);
      localStorage.setItem("itemId", itemId);
      localStorage.setItem("channelId", channelId);
      localStorage.setItem("chan", chan);
      localStorage.setItem("chanUuid", chanUuid);
      if (obj.pdaNo) {
        localStorage.setItem("pdaNo", obj.pdaNo);
      }
      // 判断是路边停车还是路外；停车场
      if (parkCode) {
        if (inteface == "unionpay") {
          // 直付码
          if (type == 5) {
            this.$axios.get(`${this.domain}/api/channel/queryPrice?channelId=${channelId}`).then((res) => {
              let orderDate = JSON.stringify(res.data.data);
              localStorage.setItem("plateNo", res.data.data.dataItems[0].plateNumber);
              this.$router.replace({
                path: "/outpark/parklotcharging",
                query: { orderDate },
              });
            });
          } else if (type != 4 && type != 6) {
            localStorage.setItem("type", 3);
            this.$router.replace("/outpark/parklotparknum");
          } else {
            this.$alert(`请使用支付宝或微信扫码`, "提示")
              .then(() => {
                return;
              })
              .catch(() => {
                return;
              });
          }
        } else {
          // 无牌车入口
          if (type == 4) {
            this.$axios
              .post(`${this.domain}/api/pay/getAuthUrl`, {
                appId,
                itemId,
                inteface,
                parkCode,
                channelId,
                type: 4,
              })
              .then((res) => {
                let url = res.data.authUrl;
                window.location.replace(url);
              });
            // 直付码
          } else if (type == 5) {
            this.$axios
              .post(`${this.domain}/api/pay/getAuthUrl`, {
                itemId,
                appId,
                inteface,
                parkCode,
                channelId,
                type: 5,
              })
              .then((res) => {
                let url = res.data.authUrl;
                window.location.replace(url);
              });
            // 无牌车出口
          } else if (type == 6) {
            this.$axios
              .post(`${this.domain}/api/pay/getAuthUrl`, {
                itemId,
                appId,
                inteface,
                type: 6,
              })
              .then((res) => {
                let url = res.data.authUrl;
                window.location.replace(url);
              });
            // 优惠券
          } else if (type == 7) {
            this.$axios
              .post(`${this.domain}/api/pay/getAuthUrl`, {
                itemId,
                appId,
                inteface,
                couponId,
                type: 7,
                parkCode,
                markType,
                couponType,
                value,
                mark,
              })
              .then((res) => {
                let url = res.data.authUrl;
                window.location.replace(url);
              });
          } else if (type == 9) {
            this.$axios
              .post(`${this.domain}/api/pay/getAuthUrl`, {
                itemId,
                appId,
                inteface,
                type: 9,
              })
              .then((res) => {
                let url = res.data.authUrl;
                window.location.replace(url);
              });
          } else if (type == 10) {
            this.$axios
              .post(`${this.domain}/api/pay/getAuthUrl`, {
                itemId,
                appId,
                inteface,
                chan,
                type: 10,
              })
              .then((res) => {
                let url = res.data.authUrl;
                window.location.replace(url);
              });
          } else if (type == 11) {
            this.$axios
              .post(`${this.domain}/api/pay/getAuthUrl`, {
                appId,
                itemId,
                inteface,
                type: 11,
              })
              .then((res) => {
                let url = res.data.authUrl;
                window.location.replace(url);
              });
          } else if (type == 12) {
            // 自研黑盒
            // 无牌车入口
            this.$axios
              .post(`${this.domain}/api/pay/getAuthUrl`, {
                appId,
                itemId,
                inteface,
                parkCode,
                channelId,
                chanUuid,
                type: 12,
              })
              .then((res) => {
                let url = res.data.authUrl;
                window.location.replace(url);
              });
            // 直付码 //无牌车出口
          } else if (type == 13) {
            this.$axios
              .post(`${this.domain}/api/pay/getAuthUrl`, {
                itemId,
                appId,
                inteface,
                parkCode,
                channelId,
                chanUuid,
                type: 13,
              })
              .then((res) => {
                let url = res.data.authUrl;
                window.location.replace(url);
              });
          } else if (type == 14) {
            this.$axios
              .post(`${this.domain}/api/pay/getAuthUrl`, {
                itemId,
                appId,
                inteface,
                parkCode,
                channelId,
                chanUuid,
                type: 14,
              })
              .then((res) => {
                let url = res.data.authUrl;
                window.location.replace(url);
              });
          } else if (type == 15) {
            this.$axios
              .post(`${this.domain}/api/pay/getAuthUrl`, {
                itemId,
                appId,
                inteface,
                businessId,
                markType,
                mark,
                couponId,
                type: 15,
              })
              .then((res) => {
                let url = res.data.authUrl;
                window.location.replace(url);
              });
          } else {
            // 车牌输入，预支付二维码
            this.$axios
              .post(`${this.domain}/api/pay/getAuthUrl`, {
                itemId,
                appId,
                inteface,
                parkCode,
                type: 3,
              })
              .then((res) => {
                let url = res.data.authUrl;
                window.location.replace(url);
              });
          }
        }
      } else {
        // 路边停车
        if (parkRecordId) {
          if (inteface == "unionpay") {
            this.$router.replace("/inpark/parkingpay");
          } else {
            this.$axios
              .post(`${this.domain}/api/pay/getAuthUrl`, {
                itemId,
                appId,
                inteface,
                parkRecorId: parkRecordId,
                type: 1,
              })
              .then((res) => {
                const jiema = decodeURIComponent(res.data.authUrl);
                let resObj = that.getRequest(jiema);
                if (resObj.inteface == "wxpay") {
                  let url = res.data.authUrl;
                  window.location.replace(url);
                } else if (resObj.inteface == "alipay") {
                  let url = res.data.authUrl;
                  window.location.replace(url);
                } else {
                  alert("不支持此支付方式，请使用支付宝或者微信支付");
                }
              });
          }
        } else {
          // 扫码取证
          if (obj.qrCodeRule) {
            this.$axios
              .post(`${this.domain}/api/pay/queryBerthQrCodeRule`, { qrCodeRule: obj.qrCodeRule })
              .then((res) => {
                localStorage.setItem("roadId", res.data.roadId);
                localStorage.setItem("berthId", res.data.berthId);
                localStorage.setItem("appId", res.data.appId);
                localStorage.setItem("itemId", res.data.itemId);
                this.$axios
                  .post(`${this.domain}/api/pay/getAuthUrl`, {
                    itemId: res.data.itemId,
                    appId: res.data.appId,
                    inteface,
                    roadId: res.data.roadId,
                    berthId: res.data.berthId,
                    type: 2,
                  })
                  .then((res) => {
                    let url = res.data.authUrl;
                    window.location.replace(url);
                  });
              });
          } else {
            let { roadId, berthId } = obj;
            localStorage.setItem("roadId", roadId);
            localStorage.setItem("berthId", berthId);
            this.$axios
              .post(`${this.domain}/api/pay/getAuthUrl`, {
                itemId,
                appId,
                inteface,
                roadId,
                berthId,
                type: 2,
              })
              .then((res) => {
                let url = res.data.authUrl;
                window.location.replace(url);
              });
          }
        }
      }
    },

    // 获取支付宝user_id
    getUserid(auth_code) {
      let that = this;
      let appId = localStorage.getItem("appId");
      let itemId = localStorage.getItem("itemId");
      this.$axios
        .post(`${this.domain}/api/pay/codeAliRedirectUri`, {
          itemId,
          appId,
          code: auth_code,
          wayCode: "ALI_JSAPI",
        })
        .then((res) => {
          if (res.data.code === 200) {
            that.alipayuserId = res.data.userId;
            localStorage.setItem("alipayuserId", that.alipayuserId);
            let { type } = this;
            let obj = this.getRequest(decodeURIComponent(window.location.href));
            let couponDate = JSON.stringify(obj);
            let itemId = localStorage.getItem("itemId");
            let channelId = localStorage.getItem("channelId");
            let chanUuid = obj.chanUuid;
            let parkCode = obj.parkCode;
            let { alipayuserId } = this;
            localStorage.setItem("isBrake", 0);
            // type为1跳转收银台
            if (type == 1) {
              this.$router.replace("/inpark/parkingpay");
            } else if (type == 2) {
              let roadId = localStorage.getItem("roadId");
              let berthId = localStorage.getItem("berthId");
              // 驻车器
              this.$axios
                .post(`${this.domain}/api/brake/check`, {
                  itemId,
                  appId,
                  berthId,
                })
                .then((res) => {
                  if (res.data.data.isBrake) {
                    localStorage.setItem("isBrake", 1);
                    this.$axios
                      .post(`${this.domain}/api/brake/show`, {
                        itemId,
                        appId,
                        berthId,
                        useId: alipayuserId,
                        payCode: "alipay",
                      })
                      .then((res) => {
                        if (res.data.code === 200) {
                          localStorage.setItem("parkRecordId", res.data.data.parkId);
                          this.$router.replace("/inpark/parkingpay");
                        } else {
                          Dialog.alert({
                            message: `${res.data.msg}`,
                          }).then(() => {
                            AlipayJSBridge.call("closeWebview");
                          });
                        }
                      })
                      .catch((e) => {
                        alert(JSON.stringify(e));
                      });
                  } else {
                    // 否则去取证
                    this.$router.replace({
                      path: "/inpark/forensics",
                      query: { roadId: roadId, berthId: berthId },
                    });
                  }
                })
                .catch((e) => {
                  alert(JSON.stringify(e));
                });
              // 车牌输入
            } else if (type == 3) {
              this.$router.replace("/outpark/parklotparknum");
            } else if (type == 4) {
              // 无牌车入口二维码
              this.$axios
                .get(
                  `${this.domain}/api/channel/noPlateNoIn?appId=${appId}&itemId=${itemId}&channelId=${channelId}&parkCode=${parkCode}&openId=${alipayuserId}`
                )
                .then((res) => {
                  if (res.data.code == 200) {
                    localStorage.setItem("inletExitType", 1);
                    localStorage.setItem("plateNo", res.data.data.plateNo);
                    this.noplateMsg = res.data.data;
                    this.noplateAlertEdit = true;
                  } else if (res.data.code == 9999) {
                    this.$alert(`${res.data.msg}`, "提示")
                      .then(() => {
                        return;
                      })
                      .catch(() => {
                        return;
                      });
                  }
                });
            } else if (type == 5) {
              // 直付码
              this.$axios.get(`${this.domain}/api/channel/queryPrice?channelId=${channelId}`).then((res) => {
                let orderDate = JSON.stringify(res.data.data);
                localStorage.setItem("plateNo", res.data.data.dataItems[0].plateNumber);
                this.$router.replace({
                  path: "/outpark/parklotcharging",
                  query: { orderDate },
                });
              });
            } else if (type == 6) {
              // 无牌车出口二维码
              this.$axios
                .get(
                  `${this.domain}/api/channel/noPlateNoOut?openId=${alipayuserId}&channelId=${channelId}&appId=${appId}&itemId=${itemId}`
                )
                .then((res) => {
                  if (res.data.code == 200) {
                    this.$router.replace({
                      path: "/outpark/parklotparknum",
                      query: {
                        plateNo: res.data.data.plateNumber,
                        recordNo: res.data.data.recordNo,
                        payScene: res.data.data.payScene,
                      },
                    });
                  } else if (res.data.code == 9999) {
                    this.$alert(`${res.data.msg}`, "提示")
                      .then(() => {
                        return;
                      })
                      .catch(() => {
                        return;
                      });
                  }
                });
            } else if (type == 7) {
              this.$router.replace({
                path: "/outpark/coupon",
                query: { couponDate },
              });
            } else if (type == 9) {
              this.$router.replace({
                path: "/outpark/monthcard",
              });
            } else if (type == 10) {
              this.$router.replace({
                path: "/outpark/exitcarcharge",
              });
            } else if (type == 12) {
              // 无牌车入口二维码
              this.$axios
                .get(
                  `${this.domain}/api/channel/zzNoPlateNoIn?appId=${appId}&itemId=${itemId}&chanUuid=${chanUuid}&parkCode=${parkCode}&openId=${alipayuserId}`
                )
                .then((res) => {
                  if (res.data.code == 200) {
                    localStorage.setItem("inletExitType", 1);
                    localStorage.setItem("plateNo", res.data.data.plateNo);
                    this.noplateMsg = res.data.data;
                    this.noplateAlertEdit = true;
                  } else if (res.data.code == 9999) {
                    Dialog.alert({
                      message: `${res.data.msg}`,
                    }).then(() => {
                      // on close
                    });
                  }
                });
            } else if (type == 13) {
              this.$axios
                .get(
                  `${this.domain}/api/channel/zzNoPlateNoOrPreOut?appId=${appId}&itemId=${itemId}&chanUuid=${chanUuid}&openId=${alipayuserId}`
                )
                .then((res) => {
                  // 直付码  无牌车出场
                  if (res.data.code == 200) {
                    // 直付码  无牌车出场
                    localStorage.setItem("inletExitType", 2);
                    let orderDate = res.data.data;
                    orderDate["chanUuid"] = chanUuid;
                    localStorage.setItem("plateNo", res.data.data.plateNo);
                    localStorage.setItem("orderNo", res.data.data.orderNo);
                    this.$router.replace({
                      path: "/outpark/blankBoxCharging",
                      query: orderDate,
                    });
                  } else {
                    Dialog.alert({
                      message: `${res.data.msg}`,
                    }).then(() => {
                      // on close
                    });
                  }
                });
            } else if (type == 14) {
              this.$router.replace("/outpark/blankBoxParklotparknum");
            } else if (type == 15) {
              this.$router.replace({
                path: "/outpark/coupon",
                query: { couponDate },
              });
            } else {
              alert("请重新扫码");
            }
          } else {
            alert("请重新扫码");
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    // 获取微信openid
    getOpenId(code) {
      let { type } = this;
      let that = this;
      let appId = localStorage.getItem("appId");
      let itemId = localStorage.getItem("itemId");
      localStorage.setItem("isBrake", 0);
      if (type == 2) {
        let berthId = localStorage.getItem("berthId");
        let roadId = localStorage.getItem("roadId");
        // 驻车器
        this.$axios
          .post(`${this.domain}/api/brake/check`, {
            itemId,
            appId,
            berthId,
          })
          .then((res) => {
            if (res.data.data.isBrake) {
              localStorage.setItem("isBrake", 1);
              this.$axios
                .post(`${this.domain}/api/pay/getUnionId`, {
                  itemId,
                  appId,
                  code,
                  wayCode: "WX_JSAPI",
                })
                .then((res) => {
                  if (res.data.code === 200) {
                    that.openId = res.data.data.openId;
                    localStorage.setItem("openId", that.openId);
                    this.$axios
                      .post(`${this.domain}/api/brake/show`, {
                        itemId,
                        appId,
                        berthId,
                        useId: res.data.data.unionId,
                        payCode: "wechat",
                      })
                      .then((res) => {
                        if (res.data.code === 200) {
                          localStorage.setItem("parkRecordId", res.data.data.parkId);
                          this.$router.replace("/inpark/parkingpay");
                        } else {
                          Dialog.alert({
                            message: `${res.data.msg}`,
                          }).then(() => {
                            WeixinJSBridge.call("closeWindow");
                          });
                        }
                      })
                      .catch((e) => {
                        alert(JSON.stringify(e));
                      });
                  } else {
                    Dialog.alert({
                      message: `获取用户信息异常,请反馈给管理员`,
                    }).then(() => {
                      // on close
                    });
                  }
                })
                .catch((e) => {
                  alert(JSON.stringify(e));
                });
            } else {
              this.$axios
                .post(`${this.domain}/api/pay/codeRedirectUri`, {
                  itemId,
                  appId,
                  code,
                  wayCode: "WX_JSAPI",
                })
                .then((res) => {
                  that.openId = res.data.data.openid;
                  localStorage.setItem("openId", that.openId);
                  this.$router.replace({
                    path: "/inpark/forensics",
                    query: { roadId: roadId, berthId: berthId },
                  });
                });
            }
          })
          .catch((e) => {
            alert(JSON.stringify(e));
          });
      } else {
        this.$axios
          .post(`${this.domain}/api/pay/codeRedirectUri`, {
            itemId,
            appId,
            code,
            wayCode: "WX_JSAPI",
          })
          .then((res) => {
            if (res.data.code === 200) {
              that.openId = res.data.data.openid;
              localStorage.setItem("openId", that.openId);
              let obj = this.getRequest(decodeURIComponent(window.location.href));
              let couponDate = JSON.stringify(obj);
              let itemId = localStorage.getItem("itemId");
              let channelId = localStorage.getItem("channelId");
              let parkCode = obj.parkCode;
              let chanUuid = obj.chanUuid;
              let { openId } = this;
              // 判断type，去缴费或者扫码取证或者停车场
              if (type == 1) {
                this.$router.replace("/inpark/parkingpay");
              } else if (type == 3) {
                this.$router.replace("/outpark/parklotparknum");
              } else if (type == 4) {
                // 无牌车入口二维码
                this.$axios
                  .get(
                    `${this.domain}/api/channel/noPlateNoIn?appId=${appId}&itemId=${itemId}&channelId=${channelId}&parkCode=${parkCode}&openId=${openId}`
                  )
                  .then((res) => {
                    if (res.data.code == 200) {
                      this.noplateMsg = res.data.data;
                      this.noplateAlertEdit = true;
                    } else if (res.data.code == 9999) {
                      this.$alert(`${res.data.msg}`, "提示")
                        .then(() => {
                          return;
                        })
                        .catch(() => {
                          return;
                        });
                    }
                  });
              } else if (type == 5) {
                // 直付码
                this.$axios.get(`${this.domain}/api/channel/queryPrice?channelId=${channelId}`).then((res) => {
                  let orderDate = JSON.stringify(res.data.data);
                  localStorage.setItem("plateNo", res.data.data.dataItems[0].plateNumber);
                  this.$router.replace({
                    path: "/outpark/parklotcharging",
                    query: { orderDate },
                  });
                });
              } else if (type == 6) {
                // 无牌车出口二维码
                this.$axios
                  .get(
                    `${this.domain}/api/channel/noPlateNoOut?openId=${openId}&channelId=${channelId}&appId=${appId}&itemId=${itemId}`
                  )
                  .then((res) => {
                    if (res.data.code == 200) {
                      this.$router.replace({
                        path: "/outpark/parklotparknum",
                        query: {
                          plateNo: res.data.data.plateNumber,
                          recordNo: res.data.data.recordNo,
                          payScene: res.data.data.payScene,
                        },
                      });
                    } else if (res.data.code == 9999) {
                      this.$alert(`${res.data.msg}`, "提示")
                        .then(() => {
                          return;
                        })
                        .catch(() => {
                          return;
                        });
                    }
                  });
              } else if (type == 7) {
                this.$router.replace({
                  path: "/outpark/coupon",
                  query: { couponDate },
                });
              } else if (type == 9) {
                this.$router.replace({
                  path: "/outpark/monthcard",
                });
              } else if (type == 10) {
                this.$router.replace({
                  path: "/outpark/exitcarcharge",
                });
              } else if (type == 11) {
                this.$router.replace({
                  path: "/outpark/monitoringVideo",
                });
              } else if (type == 12) {
                // 无牌车入口二维码
                this.$axios
                  .get(
                    `${this.domain}/api/channel/zzNoPlateNoIn?appId=${appId}&itemId=${itemId}&chanUuid=${chanUuid}&parkCode=${parkCode}&openId=${openId}`
                  )
                  .then((res) => {
                    if (res.data.code == 200) {
                      localStorage.setItem("inletExitType", 1);
                      localStorage.setItem("plateNo", res.data.data.plateNo);
                      this.noplateMsg = res.data.data;
                      this.noplateAlertEdit = true;
                    } else if (res.data.code == 9999) {
                      Dialog.alert({
                        message: `${res.data.msg}`,
                      }).then(() => {
                        // on close
                      });
                    }
                  });
              } else if (type == 13) {
                this.$axios
                  .get(
                    `${this.domain}/api/channel/zzNoPlateNoOrPreOut?appId=${appId}&itemId=${itemId}&chanUuid=${chanUuid}&openId=${openId}`
                  )
                  .then((res) => {
                    if (res.data.code == 200) {
                      // 直付码  无牌车出场
                      localStorage.setItem("inletExitType", 2);
                      let orderDate = res.data.data;
                      orderDate["chanUuid"] = chanUuid;
                      localStorage.setItem("plateNo", res.data.data.plateNo);
                      localStorage.setItem("orderNo", res.data.data.orderNo);
                      this.$router.replace({
                        path: "/outpark/blankBoxCharging",
                        query: orderDate,
                      });
                    } else {
                      Dialog.alert({
                        message: `${res.data.msg}`,
                      }).then(() => {
                        // on close
                      });
                    }
                  });
              } else if (type == 14) {
                this.$router.replace("/outpark/blankBoxParklotparknum");
              } else if (type == 15) {
                this.$router.replace({
                  path: "/outpark/coupon",
                  query: { couponDate },
                });
              } else {
                alert("请重新扫码");
              }
            } else {
              alert("请重新扫码");
            }
          })
          .catch((error) => {
            alert(error);
          });
      }
    },
    // 关闭当前页面打开新页面
    redirectUrl(url) {
      let a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", "_blank");
      a.setAttribute("id", "startTelMedicine");
      a.onclick = () => {
        //关闭窗口的方法
        window.opener = null;
        window.open("", "_self", "");
        window.close();
      };
      // 防止反复添加
      if (document.getElementById("startTelMedicine")) {
        document.body.removeChild(document.getElementById("startTelMedicine"));
      }
      document.body.appendChild(a);
      a.click();
    },
    // 获取URL中的字符串
    getRequest(str) {
      let url = str ? str : decodeURIComponent(location.search); //获取url中"?"符后的字串
      let theRequest = new Object();
      if (url.indexOf("?") != -1) {
        let index = url.indexOf("?");
        url = url.substr(index + 1);
      } else {
        alert("地址错误，请重新扫码");
      }
      if (url) {
        var strs = url.split("&");
        for (var i = 0; i < strs.length; i++) {
          var srtArry = strs[i].split("=");
          var y = srtArry.shift();
          theRequest[y] = unescape(srtArry.join("="));
        }
      }
      return theRequest;
    },
    // 定时刷新数据函数
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        this.getOrder(); //加载数据函数
      }, 60000);
    },
    // 停止定时器
    clear() {
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    // 请求计费接口
    getOrder() {
      let parkRecordId = localStorage.getItem("parkRecordId");
      let openId = localStorage.getItem("openId");
      this.$axios
        .post(`${this.domain}/api/charge/chargingForH5`, {
          parkRecordId,
          openId,
        })
        .then((res) => {
          if (res.data.code === 200 && res.data.success) {
            this.$router.replace("/inpark/parkingpay");
          } else {
            alert(res.data.msg);
          }
        });
    },
    // 判断是否是IOS系统
    checkIsAppleDevice() {
      let u = navigator.userAgent,
        app = navigator.appVersion;
      let ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      let iPad = u.indexOf("iPad") > -1;
      let iPhone = u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1;
      if (ios || iPad || iPhone) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
