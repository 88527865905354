<template>
  <transition name="fade">
    <div class="alert-box-wrapper">
      <div class="alert-box">
        <div class="sure">
          <img src="../../../assets/ok.png" alt="" />
        </div>
        <div class="alert-box-header">
          <div class="alert-box-title">抬杆成功</div>
        </div>
        <div class="alert-box-msg">您本次停车的临时车牌为</div>
        <div class="alert-box-plate" style="margin-bottom: 20px">{{ noplateMsg.plateNo }}</div>
        <div class="alert-box-content">停 车 场：{{ noplateMsg.itemName }}</div>
        <div class="alert-box-content" style="margin: 10px 0">抬杆通道：{{ noplateMsg.channelId }}</div>
        <div class="alert-box-content">入场时间：{{ nowtime }}</div>
        <div class="alert-box-prompt">
          <van-icon name="info-o" style="margin-right: 5px" />出场请用同一个支付方式扫码缴费
        </div>
        <div class="alert-box-btns">
          <van-button round type="info" class="confirm-btn" @click="handleAction">关闭</van-button>
        </div>
        <div class="remark" v-if="boxData != 2 && type != 4">
          如有疑问，您可按车场设备对接按钮呼叫人工服务或直接<span style="color: #2370ff" @click="handleCall">
            呼叫客服
          </span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "noplateAlert",
  props: {
    noplateMsg: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      nowtime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      type: localStorage.getItem("type"),
      domain: process.env.VUE_APP_DOMAIN,
      boxData: null,
    };
  },
  created() {
    this.boxType();
  },
  methods: {
    handleCall() {
      this.$router.push({
        path: "/outpark/video",
        query:{type:1}
      });
    },
    handleAction() {
      let openId = localStorage.getItem("openId");
      let userId = localStorage.getItem("alipayuserId");
      if (openId) {
        WeixinJSBridge.call("closeWindow");
      }
      if (userId) {
        AlipayJSBridge.call("closeWebview");
      }
    },
    // 盒子类型
    boxType() {
      const params = {
        itemId: localStorage.getItem("itemId"),
        appId: localStorage.getItem("appId"),
        // itemId:'ZZ-GD-SZ-KJSTY-888',
        // appId:'6209c120c5a41a9a2ee9e383',
      };
      this.$axios.post(`${this.domain}/parklot/api/hasConfigs`, params).then((res) => {
        const { code, data } = res.data;
        if (code == 200) {
          this.boxData = data.boxType;
          localStorage.setItem("boxData", this.boxData);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.alert-box-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);

  .alert-box {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 300px;
    padding: 0 15px;
    padding-bottom: 10px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 16px;
    text-align: left;

    .remark {
      margin-top: 15px;
      color: #a3a3a3;
      font-size: 15px;
      text-align: center;
    }

    .sure {
      position: absolute;
      top: -35px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 70px;
      height: 70px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .alert-box-header {
      position: relative;
      text-align: center;
      padding: 30px 0 20px 0;
      .alert-box-title {
        color: #2e3033;
        font-weight: bold;
        font-size: 20px;
      }
      .alert-box-headerbtn {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        color: #909399;
      }
    }

    .alert-box-plate {
      font-weight: 700;
      font-size: 30px;
      color: #2370ff;
      text-align: center;
    }

    .alert-box-content {
      text-align: left;
      color: #a5a5a5;
      font-size: 15px;
    }

    .alert-box-prompt {
      margin-top: 20px;
      box-sizing: border-box;
      background: rgba(235, 87, 87, 0.1);
      border-radius: 5px;
      padding: 7px 10px;
      text-align: center;
      color: #eb5757;
      font-size: 15px;
    }

    .alert-box-msg {
      text-align: center;
      color: #a5a5a5;
      font-size: 15px;
    }

    .alert-box-btns {
      margin-top: 30px;
      padding: 5px 15px 0;
      text-align: center;

      .confirm-btn {
        padding: 11px 49px;
        background: rgba(35, 112, 255, 0.1);
        border: 1px solid rgba(35, 112, 255, 0.1);
        outline: none;
        color: #2370ff;
        font-size: 16px;
      }
    }
  }
}
</style>
